import React, { useState, useEffect } from 'react';
import { Button, ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TakePhoto from './TakePhoto';
import {useAppContext} from "./AppContext";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import left1 from '../assets/img/Grupo 640.png';
import right1 from '../assets/img/Grupo 641.png';
import frutas from '../assets/img/framboise.png';
import naranjas from '../assets/img/Citrons.png';
import horizontalLogo from '../assets/img/LogoJO&Tourtel_DOUBLE_HORIZONTAL_RVB.png';
import verticalLogo from '../assets/img/LogoJO&Tourtel_DOUBLE_VERTICAL_RVB.png';
import camara from '../assets/img/Grupo 603.png';
import botellaLeft from '../assets/img/MAIN_2.png';
import botellaRight from '../assets/img/MAIN_-1.png';
import flecha from '../assets/img/Grupo 617.png';
import botellasQR from '../assets/img/Grupo 614.png';
import sillasQR from '../assets/img/Componente 23 – 1.png';
import rayasQR from '../assets/img/Grupo 591.png';
import sillasIndicators1 from '../assets/img/Grupo 646.png';
import indicators from '../assets/img/Grupo 555.png';

import cantPersonas1_1 from '../assets/img/positions/01.png';
import cantPersonas1_2 from '../assets/img/positions/02.png';
import cantPersonas1_3 from '../assets/img/positions/03.png';
import cantPersonas1_4 from '../assets/img/positions/04.png';

import cantPersonas2_1 from '../assets/img/positions/05.png';
import cantPersonas2_2 from '../assets/img/positions/06.png';
import cantPersonas2_3 from '../assets/img/positions/07.png';

import cantPersonas3_1 from '../assets/img/positions/08.png';
import cantPersonas3_2 from '../assets/img/positions/09.png';
import cantPersonas3_3 from '../assets/img/positions/10.png';

import cantPersonas4_1 from '../assets/img/positions/11.png';
import cantPersonas4_2 from '../assets/img/positions/12.png';

import cantPersonas5_1 from '../assets/img/positions/13.png';

import QRCode from 'qrcode.react';
const steps = [1, 2, 3, 4, 5, 6, 7];

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const Wizard = () => {
  const mode = process.env.REACT_APP_MODE;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const prodUrl = process.env.REACT_APP_PROD_URL;
  // const [activeStep, setActiveStep] = useState(1);
  const { images, activeStep, setActiveStep, setError } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState('');
  const [cantStep, setCantStep] = useState(8);
  const [selectedOption, setSelectedOption] = useState('1');
  const [poses, setPoses] = useState(4);
  const [currentPhoto, setCurrentPhoto] = useState(1);  
  const [intermediateStep, setIntermediateStep] = useState(1);

  const handleNext = () => {
    /**Sacar la camara*/
    if(activeStep >= 4){
        setIntermediateStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep != cantStep)
          // if(mode === 'DEVELOPMENT')
            openTakePhotoModal();
    }
    else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleStart = () => {
    window.location.href = (mode === 'DEVELOPMENT') ? baseUrl : prodUrl;
  };

  const [isTakePhotoModalOpen, setIsTakePhotoModalOpen] = useState(false);

  const openTakePhotoModal = () => {
    setIsTakePhotoModalOpen(true);
  };

  const closeTakePhotoModal = () => {
    setIsTakePhotoModalOpen(false);
  };

  const getCurrentPhoto = (photo) => {
    setCurrentPhoto(photo);
  };

  useEffect(() => {
    if (!isTakePhotoModalOpen && (activeStep === cantStep)) {
      handleUpload();
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  }, [isTakePhotoModalOpen]);

  const handleUpload = async () => {
    if (images.length === 0) {
      setError('Please select at least one image.');
      return;
    }

    setLoading(true);
    setError('');

    // const formData = new FormData();
    // images.forEach((image, index) => {
    //   formData.append(`files`, image);
    // });

    try {
      const formData = new FormData();
      if(mode === 'DEVELOPMENT'){
        const blobPromises = images.map((base64Image) => {
          const byteString = window.atob(base64Image.split(',')[1]);
          const mimeString = base64Image.split(',')[0].split(':')[1].split(';')[0];
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const int8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
          }
          return new Blob([int8Array], { type: mimeString });
        });

        const blobs = await Promise.all(blobPromises);

        blobs.forEach((blob, index) => {
          formData.append(`files`, blob);
        });
      }
      else{
        const blobPromises = images.map(url => fetch(url).then(res => res.blob()));
        const blobs = await Promise.all(blobPromises);

        blobs.forEach((blob, index) => {
          formData.append(`files`, blob);
        });
      }




      const response = await axios.post(`${apiUrl}merge-images/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      setImageName(response.data.image_url);
    } catch (error) {
      console.error('Error uploading images:', error);
      setError('Failed to upload images. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (event, newSelectedOption) => {
    if (newSelectedOption !== null) {
      setSelectedOption(newSelectedOption);
      switch(newSelectedOption){
        case '1':
          setPoses(4);
          setCantStep(8);
          break;
        case '2':
          setPoses(3);
          setCantStep(7);
          break;
        case '3':
          setPoses(3);
          setCantStep(7);
          break;
        case '4':
          setPoses(2);
          setCantStep(6);
          break;
        default:  
            setPoses(1);
          setCantStep(5);
            break;  
      }
    }
  };

  const GetImagenIntermedia = () => {
    switch (selectedOption) {      
      case '1':
          switch (currentPhoto) {
            case 4:
              return <img className="img-sillas-indicators" src={cantPersonas1_4} />;
            case 3:
              return <img className="img-sillas-indicators" src={cantPersonas1_3} />;
            case 2:
              return <img className="img-sillas-indicators" src={cantPersonas1_2} />;
            case 1:
            default:
              return <img className="img-sillas-indicators" src={cantPersonas1_1} />;
          }
      case '2':
        switch (currentPhoto) {
          case 3:
            return <img className="img-sillas-indicators" src={cantPersonas2_3} />;
          case 2:
            return <img className="img-sillas-indicators" src={cantPersonas2_2} />;
          case 1:
          default:
            return <img className="img-sillas-indicators" src={cantPersonas2_1} />;
        }
      case '3':
        switch (currentPhoto) {
          case 3:
            return <img className="img-sillas-indicators" src={cantPersonas3_3} />;
          case 2:
            return <img className="img-sillas-indicators" src={cantPersonas3_2} />;
          case 1:
          default:
            return <img className="img-sillas-indicators" src={cantPersonas3_1} />;
        }
      case '4':
        switch (currentPhoto) {
          case 2:
            return <img className="img-sillas-indicators" src={cantPersonas4_2} />;
          case 1:
          default:
            return <img className="img-sillas-indicators" src={cantPersonas4_1} />;
        }
      case '1':
      default:  
        return <img className="img-sillas-indicators" src={cantPersonas5_1} />;
    }
  }

  const renderComponente = () => {
    switch (activeStep) {      
      case 2:
        return (
          <div className='h-70vh vertical-horizontal-center flex-column text-center'>
            <h4 className='mb-10 title'>NOMBRE DE PARTICIPANT(S)</h4>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px', padding: '10px', marginBottom: '10px' }}>
              <ToggleButtonGroup
                value={selectedOption}
                exclusive
                onChange={handleOptionChange}
                aria-label="radio buttons group"
              >
                <ToggleButton className='radio-selection' value="1" aria-label="1">
                  1
                </ToggleButton>
                <ToggleButton className='radio-selection' value="2" aria-label="2">
                  2
                </ToggleButton>
                <ToggleButton className='radio-selection' value="3" aria-label="3">
                  3
                </ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                value={selectedOption}
                exclusive
                onChange={handleOptionChange}
                aria-label="radio buttons group"
              >
                <ToggleButton className='radio-selection' value="4" aria-label="4">
                  4
                </ToggleButton>
                <ToggleButton className='radio-selection' value="5" aria-label="5">
                  5 ET +
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Button className='wizard-button' onClick={handleNext}>
              <h4 className='button-title'>VALIDEZ</h4>
            </Button>
          </div>
      )
      case 3:
        return (
          <>
            <img className="img-botella-left" src={botellaLeft} onClick={handleStart} style={{cursor: 'pointer'}} />
            <div className='h-70vh vertical-horizontal-center flex-column text-center'>
              <p className='mb-10 text-title-page3' style={{color:"red"}}>A SAVOIR</p>
              <h4 className='title mt-0 mb-10 text-page3'>POUR {selectedOption} PARTICIPANT(S),</h4>
              <h4 className='title mt-0 text-page3'>IL FAUDRA PRENDRE {poses} PHOTO(S)</h4>
              <img className='camara-img' src={camara} />
              <Button className='wizard-button' onClick={handleNext}>
                <h4 className='button-title'>PRENDRE LES PHOTOS</h4>
              </Button>
            </div>
            <img className="img-botella-right" src={botellaRight} onClick={handleStart} style={{cursor: 'pointer'}} />
          </>
      )
      case 1:
        return (
            <>
              <img className="img-left" src={left1} />
              <div className="step1">
                <div className='container-union-frutas'>
                  <div className='union-frutas'>
                    <img src={naranjas} />
                    <img src={frutas} />
                  </div>
                  <Button className='wizard-button' onClick={handleNext}>
                    <h4 className='button-title'>DÉMARREZ L'EXPÉRIENCE</h4>
                  </Button>
                </div>
                <div className='container-horizontal-logo'>
                  <img className="horizontal-logo" src={horizontalLogo} />
                </div>
              </div>
              <img className="img-right" src={right1} />
            </>
        )
      default:
        /**Muestro la pantalla final*/
        if(activeStep === cantStep)
          return (
              <>
                <img className="img-qr-rayas" src={rayasQR} />
                <div className="img-qr-result">
                  <img src={`${apiUrl}image/${imageName}`}/>
                </div>
                <div className='h-70vh vertical-horizontal-center flex-column text-center'>
                  <div className='qr-container'>
                    <QRCode value={`${baseUrl}qr/${imageName}`} />
                    <div className='text'>
                      <h4 className='title-green'>SCANNEZ</h4>
                      <h4 className='title-green'>LE QR CODE</h4>
                      <h4 className='title-green'>POUR</h4>
                      <h4 className='title-green'>RÉCUPÉREZ</h4>
                      <h4 className='title-green'>VOTRE PHOTO</h4>
                    </div>
                    <img className="img-qr-flecha" src={flecha} />
                  </div>
                  <div className='container-title-green'>
                    <h3 className='title-green mt-0 mb-10'>TOURTEL TWIST VOUS REMERCIE</h3>
                    <h3 className='title-green mt-0'>POUR VOTRE PARTICIPATION</h3>
                  </div>
                </div>
                <img className="img-qr-botellas" src={botellasQR} onClick={handleStart} style={{cursor: 'pointer'}} />
              </>
          )
        else/**Muestro los pasos intermedios**/
          return (
              <div className='h-70vh vertical-horizontal-center flex-column text-center'>
                {GetImagenIntermedia()}
                <img className="img-indicators-steps" src={indicators} />
                <h4 className='mb-10 title'>PRISE DE VUE <span className='title-green' style={{fontSize: '30px'}}>N°{intermediateStep}</span>/{poses}</h4>
                <h4 style={{fontSize: '28px', marginTop: '10px !important', color: "green"}}>ETAPE 1</h4>
                <div>
                  <h3 className='mt-0 mb-10 title'>PLACEZ LES VISITEURS</h3>
                  <h3 className='mt-0 title'>SUR LES PLACES MARQUEES</h3>
                </div>
                <h4 style={{fontSize: '28px', marginTop: '10px !important', color: "green"}}>ETAPE 2</h4>
                <div>
                  <h3 className='mt-0 mb-10 title'>APPUYEZ SUR LE DÉCLENCHEUR</h3>
                  <h3 className='mt-0 title'>POUR PRENDRE LA PHOTO</h3>
                </div>
                <Button className='wizard-button' onClick={handleNext}>
                  {/*<h4>{(activeStep + 1) === cantStep ? "VOIR LA PHOTO" : "PHOTO SUIVANTE"}</h4>*/}
                  <h4>PHOTO SUIVANTE</h4>
                </Button>
                <img className="img-camera-steps" src={camara} />
                <img className="img-qr-botellas" src={botellasQR} onClick={handleStart} style={{cursor: 'pointer'}} />
              </div>
          )
    }
  };

  return (
      <div className={activeStep == 1 ? 'first-background main-container' : 'other-background main-container'}>        
          {(activeStep > 1 && activeStep < 7) && <img className='vertical-logo' src={verticalLogo} onClick={handleStart} />}
          {loading ? 
          <ThemeProvider theme={theme}>
            <div className='vertical-horizontal-center h-80vh'>
                <CircularProgress />
            </div>
        </ThemeProvider> : renderComponente()}
        {isTakePhotoModalOpen && !loading && (
            <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  display: 'grid',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 9999,
                }}
            >
              <div>
                <TakePhoto closeTakePhotoModal={closeTakePhotoModal} poses={poses} getCurrentPhoto={getCurrentPhoto} />
              </div>
            </div>
        )}
      </div>

  );
};

export default Wizard;
