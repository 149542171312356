import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import horizontalLogo from '../assets/img/LogoJO&Tourtel_DOUBLE_HORIZONTAL_RVB.png';
import botellas from '../assets/img/Sans titre-2.png';

const QrScan = () => {
  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {    
    const imageSrc = imageUrl+id;
    setImageUrl(imageSrc);
  }, [id]);

  const handleDownloadImage = () => {
    const link = document.createElement('a');
    link.href = `${apiUrl}image/${imageUrl}`;
    link.setAttribute('download', `image_${id}.jpg`);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadImageFetch = () => {
    fetch(`${apiUrl}image/${imageUrl}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `image_${id}.jpg`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => console.error('Error al descargar la imagen', error));
  };  

  return (
    <div className='qr-background'> 
      <div className='p-0 text-center qr-page'>
          <div class="image-container">
            <img src={`${apiUrl}image/${imageUrl}`} />  
          </div>
          <Button className='wizard-button m-w-100' onClick={handleDownloadImageFetch}>
            <h4>TELECHARGER MA PHOTO</h4>
          </Button>
          <img className="botellas-img" src={botellas} />
          <img className="horizontal-logo" src={horizontalLogo} />
      </div>      
    </div>
  );
};

export default QrScan;
