import React from 'react';
import './assets/css/styles.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Usa BrowserRouter como Router si prefieres usar BrowserRouter

import Wizard from './components/Wizard';
import QrScan from './components/QrScan';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Wizard />} />
        <Route path="/qr/:id" element={<QrScan />} />
      </Routes>
    </Router>
  );
}

export default App;
