import React, { useState, useCallback, useEffect  } from 'react';
import CameraPhoto, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {
  Card,
  Button,
} from '@mui/material';
import { useAppContext } from './AppContext';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import sillasIndicators1 from "../assets/img/Grupo 646.png";
import indicators from "../assets/img/Grupo 555.png";
import camara from "../assets/img/Grupo 603.png";
import botellasQR from "../assets/img/Grupo 614.png";

const cardStyle = {
  backgroundColor: '#000000',
  border: '1px solid gray',
  borderRadius: '20px',
};

const TakePhoto = (props) => {
  const mode = process.env.REACT_APP_MODE;
  const [showCamera, setShowCamera] = useState(true);
  const {images, setImages, activeStep, setActiveStep, takePhoto, setTakePhoto, error, setError} = useAppContext();
  const [loading, setLoading] = useState(false);
  // const [takePhoto, setTakePhoto] = useState(1);

  const [showPreview, setShowPreview] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const [callApi, setCallApi] = useState(false);

  const handleTakePhoto = useCallback((dataUri) => {
    setImageSrc(dataUri);
    setShowPreview(true);
  }, []);

  const handleRetakePhoto = () => {
    setError('');
    if(mode === 'DEVELOPMENT'){
      setImageSrc(null);
      setShowCamera(true);
      setShowPreview(false);
    }
    else{
      setCallApi(true);
      callApiCamera();
    }
  };

  const handleAcceptPhoto = () => {
    setError('');
    setImages([...images, imageSrc]);
    setImageSrc(null);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (takePhoto === props.poses)
      setTakePhoto(1)
    else
      setTakePhoto(takePhoto + 1);
    props.getCurrentPhoto(takePhoto + 1)
    props.closeTakePhotoModal();

    // if(takePhoto < props.poses){
    //   setShowCamera(true);
    //   setTakePhoto(takePhoto + 1);
    //   setShowPreview(false);
    // }
    // else{
    //   setTakePhoto(1);
    //   setShowCamera(false);
    // }

  };

  const callApiCamera = async () =>{
    setLoading(true);
    axios.get('https://tourtel-camera.natasquad.com/api/photo', {
      responseType: 'blob',
    })
        .then(response => {
          const imageUrl = URL.createObjectURL(new Blob([response.data]));
          setImageSrc(imageUrl);
          setShowPreview(true);
        })
        .catch(error => {
          if (axios.isAxiosError(error)) {
            if (error.code === 'ERR_NETWORK') {
              setError(error.message);
            } else if(error.response && error.response.status >= 400) {
              setError(error.message);
              console.error('Hubo un problema con la petición:', error.message);
            } else if (!error.response) {
              console.error('Hubo un problema con la petición:', error);
              setError('Veuillez vérifier que la caméra est correctement connectée via le câble USB.');
            }
          } else {
            setError(error.message);
            console.error('Hubo un problema inesperado:', error.message);
          }
        })
        .finally(final=> {
          setLoading(false);
    });
  }
  // useEffect(() => {
  //   if(mode != 'DEVELOPMENT')
  //     callApiCamera();
  // }, []);

  const handleCallApi = async () =>{
    setCallApi(true);
    callApiCamera();
  }

  return (
    <div
  className=''
    >
      <Card style={cardStyle}>
              <div>
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {!error && mode === 'DEVELOPMENT' && showPreview && imageSrc && (
                    <div>
                      <img src={imageSrc} alt="Captured"/>
                    </div>
                )}
                {!error && mode != 'DEVELOPMENT' && (
                    <div className="preview-overlay">
                      {callApi ? (loading ?
                            (<div className='vertical-horizontal-center h-80vh'>
                              <CircularProgress/>
                            </div>)
                            : <img src={imageSrc} alt="Captured" className="preview-image" />) : (
                          <div className='h-70vh vertical-horizontal-center flex-column text-center'>
                            <div>
                              <h3 className='mt-0 mb-10 title'>Appuie sur le bouton pour prendre la photo</h3>
                            </div>
                            <Button className='wizard-button' onClick={handleCallApi}>
                              <h4>Prendre une photo</h4>
                            </Button>
                          </div>
                      )}




                    </div>
                )}
                {!error && mode === 'DEVELOPMENT' && !showPreview && !imageSrc && (
                    <CameraPhoto
                        onTakePhoto={handleTakePhoto}
                        isImageMirror={false}
                        idealFacingMode={FACING_MODES.USER}
                    />
                )}
              </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
          <label style={{ color: 'white', float: "right", padding: 5, marginRight: 10, fontWeight: "bold" }}>
            {takePhoto}/{props.poses} PHOTOS
          </label>
          {error && (
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
                <button style={{ backgroundColor: '#fff', color: 'black', border: 'none', padding: '5px 10px', borderRadius: '5px'}} onClick={handleRetakePhoto}>{'Reload'}</button>
              </div>
          )}
          {!error && showPreview && imageSrc && (
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
                <button style={{ backgroundColor: '#fff', color: 'black', border: 'none', padding: '5px 10px', borderRadius: '5px' }} onClick={handleAcceptPhoto}>{takePhoto === props.poses ? 'VALIDER' : 'VALIDER'} </button>
                <button style={{ backgroundColor: '#fff', color: 'black', border: 'none', padding: '5px 10px', borderRadius: '5px'}} onClick={handleRetakePhoto}>{mode === 'DEVELOPMENT' ? 'RECOMMENCER' : 'RECOMMENCER'}</button>
              </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default TakePhoto;




  





























